import { useEffect, useState } from "react";
import { builder } from "@builder.io/sdk";
import { navigate } from "gatsby";
import useDevice from "@hooks/useDevice";

type UsePageModelDataProps = {
  model: string;
  urlPath: string;
  userAttr?: Record<string, {}>;
};

/**
 * @function usePageModelData
 * @description this is a hook used to get a page modal content client side. This allows all the dev to have more, consistency when fetching the model content and can help reducing the logic repetition.
 * @param model - name of the model that you want to target.
 * @param urlPath - current url to get the right content.
 * @param userAttr - this is an optional param used for custom targeting.
 *
 * @example
 * const content = usePageModelData<Queries.builder_PageFullNav["content"]>({
 *  model: "page-full-nav",
 *  urlPath: location.pathname,
 *  userAttr: {
 *    customer: true,
 *   },
 * });
 */

export function usePageModelData<T>({
  model,
  urlPath,
  userAttr = {},
}: UsePageModelDataProps) {
  const [content, setContent] = useState<T | null>(null);
  const device = useDevice();

  useEffect(() => {
    builder
      .get(model, {
        userAttributes: {
          urlPath,
          device,
          ...userAttr,
        },
        options: {
          cachebust: true,
          noTraverse: false,
          includeRefs: true,
        },
      })
      .toPromise()
      .then(res => {
        if (!res) navigate("/404/");

        setContent(res);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return content;
}
