import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterFaqsContainer } from "@components/BuilderWrapperComponents/FaqsAccordionBC";
import { RegisterLinkRedirectSignin } from "@components/BuilderWrapperComponents/LinkRedirectSigninWrapper";
import { RegisterSignUpForm } from "@components/BuilderWrapperComponents/SignUpFormBC";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";
import { RegisterCtaNew } from "../components/RedesignComponents/BuilderWrappers/Cta/Cta";
import { RegisterVideoEmbedWrapper } from "../components/RedesignComponents/BuilderWrappers/VideoEmbedWrapper/VideoEmbedWrapper";
import { RegisterFAQsAccordion } from "../components/RedesignComponents/FAQsAccordion";
import { RegisterSectionWithMargin } from "../components/RedesignComponents/SectionWithMargin/SectionWithMargin";

export const RegisterComponentSignForm = () => {
  RegisterSignUpForm();
  RegisterFaqsContainer();
  RegisterPricingPageToolTips();
  RegisterLinkRedirectSignin();
  RegisterSignupFormDrip();
  RegisterCtaNew();
  RegisterVideoEmbedWrapper();
  RegisterSectionWithMargin();
  RegisterFAQsAccordion();
};
